import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { faCubes } from "@fortawesome/free-solid-svg-icons";
import {
    faBoxCircleCheck,
    faMobileScreenButton,
    faTruckContainer,
    faUserHelmetSafety,
} from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import Grid from "@mui/material/Grid";
import { notification } from "antd";
import React, { useRef, useState } from "react";
import { trackPromise } from "react-promise-tracker";
import { globalAppConfig } from "../../config";
import InstallPLOTModal from "../Project/installPLOTModal";
import ContactSupport from "../Support/ContactSupport";
import Modal from "../UI/Modal";

interface FeedbackHelpModalProps {
    showModal: boolean;
    onClose: () => void;
}

const FeedbackHelpModal: React.FC<FeedbackHelpModalProps> = ({ showModal, onClose }) => {
    const formRef = useRef<any>();
    const [userInfo, setUserInfo] = useState({
        email: null,
        fullname: null,
        message: null,
        url: window.location.href.toString(),
    });
    const [installPLOTModalVisible, setInstallPLOTModalVisible] = useState(false);

    const handleUpdate = (e: React.ChangeEvent<HTMLInputElement>) => {
        setUserInfo({
            ...userInfo,
            [e.target.id]: e.target.value,
        });
    };

    const onFinish = (e) => {
        e.preventDefault();
        const url = "/me/feedback";
        trackPromise(
            fetch(url, {
                method: "post",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(userInfo),
            })
                .then((data) => {
                    if (data.ok) {
                        return data.json();
                    }
                    throw new Error("Network error.");
                })
                .then(() => {
                    notification.success({
                        message: "Feedback Submitted",
                        description: "Thank you for submitting your feedback!",
                        duration: globalAppConfig.NOTIFICATION_DURATION,
                    });

                    onClose();
                })
                .catch((err) => {
                    notification.error({
                        message: "Failed to Share Feedback",
                        description: "The feedback could not be shared: " + err,
                        duration: globalAppConfig.NOTIFICATION_DURATION,
                    });
                })
        );
    };

    return (
        <>
            <Modal
                destroyOnClose={true}
                title="Get Help or Share Feedback"
                open={showModal}
                onCancel={onClose}
                width={400}
                footer={null}
            >
                <div style={{ textAlign: "center" }}>
                    {/* <p>Check out our Quick Start Guide and other helpful links for info on using PLOT</p> */}
                    <p className="text-center text-lg font-plot-oswald">Check out our help articles</p>
                    <Grid container spacing={2} style={{ marginTop: "0.1em" }}>
                        <Grid item xs={6}>
                            <a
                                href="https://intercom.help/plotjobsites/en/articles/6460671-1-logistics-page"
                                target="_blank"
                                rel="noreferrer noopener"
                            >
                                <button className="plot-button alternate-button block-button select-none" type="button">
                                    <FontAwesomeIcon icon={faBoxCircleCheck} />
                                    <span style={{ marginLeft: "5px" }}>1 Logistics</span>
                                </button>
                            </a>
                        </Grid>
                        <Grid item xs={6}>
                            <a
                                href="https://intercom.help/plotjobsites/en/articles/6460675-2-companies-user-administration"
                                target="_blank"
                                rel="noreferrer noopener"
                            >
                                <button className="plot-button alternate-button block-button select-none" type="button">
                                    <FontAwesomeIcon icon={faUserHelmetSafety as IconProp} />
                                    <span style={{ marginLeft: "5px" }}>2 Companies</span>
                                </button>
                            </a>
                        </Grid>
                        <Grid item xs={6}>
                            <a
                                href="https://intercom.help/plotjobsites/en/articles/6460673-3-deliveries-calendar"
                                target="_blank"
                                rel="noreferrer noopener"
                            >
                                <button className="plot-button alternate-button block-button select-none" type="button">
                                    <FontAwesomeIcon icon={faTruckContainer as IconProp} />
                                    <span style={{ marginLeft: "5px" }}>3 Deliveries</span>
                                </button>
                            </a>
                        </Grid>
                        <Grid item xs={6}>
                            <a
                                href="https://intercom.help/plotjobsites/en/articles/8954128-procurement-scheduling-definitions"
                                target="_blank"
                                rel="noreferrer noopener"
                            >
                                <button className="plot-button alternate-button block-button select-none" type="button">
                                    <FontAwesomeIcon icon={faCubes as IconProp} />
                                    <span style={{ marginLeft: "5px" }}>4 Procurement</span>
                                </button>
                            </a>
                        </Grid>
                        <Grid item xs={12}>
                            <button
                                onClick={() => {
                                    setInstallPLOTModalVisible(!installPLOTModalVisible);
                                }}
                                className="plot-button alternate-button block-button select-none"
                                type="button"
                            >
                                <FontAwesomeIcon icon={faMobileScreenButton as IconProp} />
                                <span style={{ marginLeft: "5px" }}>Download Desktop and Mobile Apps</span>
                            </button>
                            <InstallPLOTModal
                                visible={installPLOTModalVisible}
                                hideModal={() => {
                                    setInstallPLOTModalVisible(!installPLOTModalVisible);
                                }}
                            />
                        </Grid>
                    </Grid>

                    <hr
                        style={{
                            width: "100%",
                            marginTop: "10px",
                            marginBottom: "10px",
                        }}
                    />
                </div>
                <ContactSupport onClose={onClose} />
            </Modal>
        </>
    );
};

export default FeedbackHelpModal;
