import { TextField } from "@mui/material";
import { notification } from "antd";
import React, { useContext, useEffect, useState } from "react";
import { globalAppConfig } from "../../config";
import GlobalStoreContext from "../../context/GlobalStoreContext";
import Helper from "../../Helper";

interface ContactSupportProps {
    onClose: () => void;
}

const ContactSupport: React.FC<ContactSupportProps> = ({ onClose }) => {
    const [userInfo, setUserInfo] = useState<{
        email: string | null;
        fullname: string | null;
        message: string | null;
        project: number | null;
        url: string;
    }>({
        email: null,
        fullname: null,
        message: null,
        project: null,
        url: window.location.href.toString(),
    });

    const context = useContext(GlobalStoreContext);

    useEffect(() => {
        if (context.userInfo) {
            const userPreferredEmail = context.userInfo?.emails.find(
                (email) => email.receive_notifications && email.verified
            )?.email;

            setUserInfo({
                ...userInfo,
                fullname: context.userInfo.fullname || null,
                email: userPreferredEmail || null,
                project: context.currentProjectInfo?.id || null,
            });
        }
    }, [context.userInfo, context.currentProjectInfo]);

    // useEffect(() => {
    //     if (context.currentProjectInfo) {
    //         setUserInfo({
    //             ...userInfo,
    //             project: context.currentProjectInfo.id,
    //         });
    //     }
    // }, [context.currentProjectInfo]);

    const handleUpdate = (e: React.ChangeEvent<HTMLInputElement>) => {
        setUserInfo({
            ...userInfo,
            [e.target.id]: e.target.value,
        });
    };

    const onFinish = (e) => {
        if (userInfo.email === null || userInfo.fullname === null || userInfo.message === null) {
            notification.error({
                message: "Missing Required Fields",
                description: "Please fill out all fields before submitting.",
                duration: globalAppConfig.NOTIFICATION_DURATION,
            });
            return;
        }
        e.preventDefault();
        const url = `${Helper.baseUrlWithPort()}/users/me/contact_support`;
        const options = {
            method: "post",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify(userInfo),
        };

        return Helper.plotFetch(url, options, true).then((response) => {
            if (response.ok) {
                notification.success({
                    message: "Support Request Submitted",
                    description: "Thank you! A ticket has been created regarding your issue.",
                    duration: globalAppConfig.NOTIFICATION_DURATION,
                });
                onClose();
            } else {
                notification.error({
                    message: "Failed to Create Support Request",
                    description: response.statusText,
                    duration: globalAppConfig.NOTIFICATION_DURATION,
                });
            }
        });
    };

    return (
        <form className="flex flex-col gap-2">
            <p className="text-center text-lg font-plot-oswald">Contact Support</p>
            <TextField
                InputLabelProps={{ shrink: userInfo.fullname !== null }}
                required
                id="fullname"
                label="Full Name"
                variant="standard"
                value={userInfo.fullname}
                fullWidth
                onChange={handleUpdate}
            />
            <TextField
                InputLabelProps={{ shrink: userInfo.email !== null }}
                required
                id="email"
                label="Email Address"
                variant="standard"
                value={userInfo.email}
                fullWidth
                onChange={handleUpdate}
            />
            <TextField
                InputLabelProps={{ shrink: userInfo.message !== null }}
                required
                id="message"
                label="Message"
                variant="standard"
                multiline
                rows={4}
                fullWidth
                onChange={handleUpdate}
            />

            <button type="submit" className="plot-button primary-button w-full" onClick={onFinish}>
                Send
            </button>
        </form>
    );
};

export default ContactSupport;
